import React from 'react';
import CenteredCarousel from '../components/CenteredCarousel';
import FeatureGrid from '../components/Features';

function Inicio() {
    return (
        <>
            <div>
                <div className="relative text-white overflow-hidden">
                    {/* Video de fondo */}
                    <div className="absolute inset-0 w-full h-full">
                        <video
                            className="w-full h-full object-cover"
                            autoPlay
                            muted
                            loop
                            playsInline
                            loading="lazy"
                        >
                            <source src="videos/fundicion.mp4" type="video/mp4" />
                            Tu navegador no soporta el video.
                        </video>
                        <div className="absolute inset-0 bg-black opacity-50"></div> {/* Filtro oscuro */}
                    </div>

                    <div className="container mx-auto px-12 py-24 md:py-32 relative z-10">
                        <div className="flex flex-col md:flex-row items-center justify-between">
                            <div className="w-full md:w-1/2 mb-12 md:mb-0">
                                <h1 className="text-4xl sm:text-5xl lg:text-6xl font-boldCondensed mb-6 leading-tight overflow-hidden">
                                    Construye.<br />
                                    Revoluciona.<br />
                                    Evoluciona.
                                </h1>
                                <p className="text-sm sm:text-base lg:text-lg mb-8">
                                    Soluciones avanzadas en prefabricados de hormigón para tu proyecto de construcción.
                                </p>
                                <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 ">
                                    <a href="/proyectos" className="text-sm sm:text-base lg:text-lg border-2 border-white text-white font-semibold px-8 py-3 rounded-full hover:bg-white hover:text-bright-red transition duration-300 text-center items-center justify-center">
                                        Ver proyectos
                                    </a>
                                    <a href="/contacto" className="text-sm sm:text-base lg:text-lg border-2 border-white text-white font-semibold px-8 py-3 rounded-full hover:bg-white hover:text-bright-red transition duration-300 text-center items-center justify-center">
                                        Contáctanos
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FeatureGrid />
                <div className="flex flex-col md:flex-row gap-8 items-center lg:items-start justify-center max-w-7xl mx-auto p-8">
                    {/* Imagen */}
                    <div className="flex-1 animate-slide-in-left">
                        <img
                            src="/images/inicio.webp"
                            className="rounded-lg object-cover w-full h-auto"
                            alt="Hormigonera entregando material de construcción en un proyecto"
                        />
                    </div>

                    {/* Texto y lista */}
                    <div className="flex-1 flex flex-col items-center md:items-start mt-10 md:ml-10 w-full animate-slide-in-right text-darkGray">
                        <div>
                            <h3 className="font-boldCondensed text-lg sm:text-xl lg:text-3xl mb-4 lg:mb-10">
                                Nuestros elementos
                            </h3>
                            <ul className="text-sm sm:text-base lg:text-lg space-y-4">
                                <li>Aceras y bordillos</li>
                                <li>Losa de cimentación</li>
                                <li>Piso de hormigón</li>
                                <li>Muros de contención</li>
                                <li>Zapata de cimentación</li>
                            </ul>
                            {/* Botón */}
                            <div className="mt-6 lg:mt-12">
                                <a href="/elementos" class="flex items-center text-sm sm:text-base lg:text-lg  text-bright-red border border-bright-red py-2 px-6 gap-2 rounded-lg inline-flex items-center hover:bg-bright-red hover:text-white transform duration-500">
                                    <span>
                                        Ver más
                                    </span>
                                    <svg
                                        className="w-4"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='px-4 py-12 lg:py-16'>
                    <div className='mb-14'>
                    </div>
                    <div className="max-w-7xl w-full mx-auto text-center px-4 mb-20 animate-slide-in-left">
                        <h2 className="font-boldCondensed text-darkGray text-lg sm:text-xl lg:text-3xl mb-8 overflow-hidden">
                            Nuestros proyectos destacados
                        </h2>
                        <p className="font-sans text-darkGray text-sm sm:text-base lg:text-lg">
                            Proyectos realizados con excelencia y compromiso, adaptados a los más altos estándares del sector.
                        </p>
                    </div>
                    <div className='mx-auto text-center'>
                        <CenteredCarousel />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Inicio;
