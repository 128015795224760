import React from 'react';
import { FaPhone } from 'react-icons/fa';

const WhatsAppButton = () => {
  return (
    <a
      href="https://wa.me/0963630274" 
      className="fixed bottom-4 right-4 bg-bright-red text-white p-4 rounded-full shadow-lg z-50"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Enviar un mensaje por WhatsApp"
    >
      <FaPhone size={24} />
    </a>
  );
};

export default WhatsAppButton;
