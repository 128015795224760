import React, { useState } from 'react';


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav class="bg-white border-darkGray py-4 shadow-lg">
      <div class="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto ">
        <a href="/" class="flex items-center">
          <img
            src="/images/logos/Logo.webp"
            width="auto"
            height="48"
            class="w-8 h-8 mr-2"
            alt="Hormipen Logo"
          />
          <img
            src="/images/logos/Logo-Gray.webp"
            width="auto"
            height="48"
            class="h-8 lg:h-9"
            alt="Hormipen Logo Gris"
          />
        </a>

        <div class="flex items-center lg:order-2">
          <button
            onClick={toggleMenu}
            data-collapse-toggle="mobile-menu-2"
            type="button"
            className="inline-flex items-center p-2 ml-1 text-sm text-darkGray rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-darkGray"
            aria-controls="mobile-menu-2"
            aria-expanded={isOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
            </svg>
            <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
            </svg>
          </button>
        </div>
        <div className={`items-center justify-between w-full lg:flex lg:w-auto lg:order-1 ${isOpen ? '' : 'hidden'}`} id="mobile-menu-2">
          <ul class="flex flex-col mt-2 font-boldCondensed text-darkGray lg:flex-row lg:space-x-8 lg:mt-0 text-xl">
            <li>
              <a href="/" class="block py-2 pl-3 pr-4 border-b border-darkGray hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-bright-red lg:p-0 transition-colors duration-300 animate-fade-in group">
                Inicio
                <span className="hidden lg:block absolute bottom-0 left-0 w-full h-[2px] bg-bright-red scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
              </a>
            </li>
            <li>
              <a href="/nosotros" class="block py-2 pl-3 pr-4 border-b border-darkGray hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-bright-red lg:p-0 transition-colors duration-300 animate-fade-in group">
                Nosotros
                <span className="hidden lg:block absolute bottom-0 left-0 w-full h-[2px] bg-bright-red scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
              </a>
            </li>
            <li>
              <a href="/proyectos" class="block py-2 pl-3 pr-4 border-b border-darkGray hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-bright-red lg:p-0 transition-colors duration-300 animate-fade-in group">
                Proyectos
                <span className="hidden lg:block absolute bottom-0 left-0 w-full h-[2px] bg-bright-red scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
              </a>
            </li>
            <li>
              <a href="/elementos" class="block py-2 pl-3 pr-4 border-b border-darkGray hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-bright-red lg:p-0 transition-colors duration-300 animate-fade-in group">
                Elementos
                <span className="hidden lg:block absolute bottom-0 left-0 w-full h-[2px] bg-bright-red scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
              </a>
            </li>
            <li>
              <a href="/contacto" class="block py-2 pl-3 pr-4 border-b border-darkGray hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-bright-red lg:p-0 transition-colors duration-300 animate-fade-in group">
                Contacto
                <span className="hidden lg:block absolute bottom-0 left-0 w-full h-[2px] bg-bright-red scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
              </a>
            </li>

          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
