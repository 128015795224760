import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import ProyectoDetalle from "./pages/ProyectoDetalle";
import Footer from './components/Footer';
import WhatsAppButton from './components/WhatsAppButton';
import Nosotros from './pages/Nosotros';
import Elementos from './pages/Elementos';
import Contacto from './pages/Contacto';
import Proyectos from './pages/Proyectos';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ProjectProvider } from "./pages/ProjectContext";
import Inicio from './pages/Inicio';



const App = () => {

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <div className="font-din">
          {/* <div className="bg-white py-2 hidden md:block">
          <TopOptions />
        </div> */}
          <div className="bg-white">
            <Navbar />
          </div>

          {/* ESTO DIV SI SE QUEDA, PERO CONSIDERO QUE SOLO EN Inicio */}

          <main className="w-full mx-auto">
            <Routes>
              {/* DANIEL */}
              <Route path="/nosotros" element={<Nosotros />} />
              <Route path="/contacto" element={<Contacto />} />
              <Route path="/proyectos" element={<ProjectProvider> <Proyectos /></ProjectProvider>} />
              <Route path="/proyectos/:id" element={<ProjectProvider> <ProyectoDetalle /></ProjectProvider>} />
              {/* NUEVO */}
              <Route path="/elementos" element={<Elementos />} />
              <Route path="/" element={
                <>
                  <Inicio />
                  {/* <NewsSection /> */}
                </>
              } />
            </Routes>
          </main>
          <Footer />
          <WhatsAppButton />
        </div>
      </Router>
    </Suspense>

  );
};

export default App;
