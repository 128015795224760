import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useProjects } from "./ProjectContext";

function ProyectoDetalle() {
    const { id } = useParams();
    const projects = useProjects();

    const mainVideoRef = useRef(null);
    const secondaryVideoRef = useRef(null);

    const [triggerAnimation, setTriggerAnimation] = useState(false); // Controla la animación

    const project = projects.find((project) => project.id.toLowerCase() === id.toLowerCase());

    useEffect(() => {
        const textElement = document.querySelector("#animatedText");

        if (!textElement) return;
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setTriggerAnimation(true);
                    observer.disconnect(); // Detén la observación después de activar la animación
                }
            },
            {
                threshold: 0.5,
            }
        );

        observer.observe(textElement);

        return () => {
            if (observer) observer.disconnect();
        };
    }, []);

    if (!project) {
        return (
            <div>
                <h1>Proyecto no encontrado</h1>
                <p>ID recibido: {id}</p>
            </div>
        );
    }

    const handleSecondaryVideoPlay = () => {
        if (mainVideoRef.current) {
            mainVideoRef.current.pause();
        }
    };

    const handleSecondaryVideoPause = () => {
        if (mainVideoRef.current) {
            mainVideoRef.current.play();
        }
    };

    
    return (
        <div className="flex flex-col lg:flex-row sm:p-2 lg:p-4 gap-6 duration-4000">

            <div className="flex-1 sm:p-1 md:p-2 animate-slide-in-left">
                <video
                    ref={mainVideoRef}
                    autoPlay
                    muted
                    loop
                    playsInline
                    loading="lazy"
                    className="lg:w-full"
                >
                    <source src={`/images/proyectos/${project.description}/${project.video}`} alt={project.description} type="video/mp4" />
                </video>
            </div>

            {/* Detalles del proyecto */}
            <div className="flex-1 p-4">
                <section>
                    <h3
                        className="text-xs sm:text-sm md:text-xl font-boldCondensed uppercase p-4 text-center text-bright-red overflow-hidden"
                    >
                        {project.title}
                    </h3>

                    <h1
                        className="text-4xl sm:text-5xl lg:text-6xl font-boldCondensed uppercase p-4 text-center text-bright-red overflow-hidden"
                    >
                        {project.description}
                    </h1>
                    <p className="text-justify text-sm sm:text-base lg:text-lg p-2 animate-slide-in-right text-darkGray" style={{ hyphens: "auto" }}>
                        {project.detalle}
                    </p>

                    <div className="p-4 max-w-4xl mx-auto animate-slide-in-right">
                        <h3 className="text-lg sm:text-xl lg:text-3xl font-boldCondensed text-bright-red text-center">
                            Detalles del Proyecto
                        </h3>
                        <div className="overflow-x-auto mt-2">
                            <table className="table-auto w-full border-collapse border border-darkGray" style={{ hyphens: "auto" }}>
                                <tbody className="text-sm sm:text-base lg:text-lg text-justify">
                                    <tr className="border-b border-darkGray">
                                        <td className="px-4 py-2 font-semibold text-darkGray">Cliente:</td>
                                        <td className="px-4 py-2 text-darkGray ">{project.cliente}</td>
                                    </tr>
                                    <tr className="border-b border-darkGray">
                                        <td className="px-4 py-2 font-semibold text-darkGray ">Lugar:</td>
                                        <td className="px-4 py-2 text-darkGray ">{project.lugar}</td>
                                    </tr>
                                    <tr className="border-b border-darkGray">
                                        <td className="px-4 py-2 font-semibold text-darkGray ">
                                            Fecha de inicio:
                                        </td>
                                        <td className="px-4 py-2 text-darkGray ">{project.fechaInicio}</td>
                                    </tr>
                                    <tr className="border-b border-darkGray">
                                        <td className="px-4 py-2 font-semibold text-darkGray ">Características:</td>
                                        <td className="px-4 py-2 text-darkGray ">{project.caracteristicas}</td>
                                    </tr>
                                    <tr className="border-b border-darkGray">
                                        <td className="px-4 py-2 font-semibold text-darkGray ">Concreto Total:</td>
                                        <td className="px-4 py-2 text-darkGray ">{project.volumenTotal}</td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2 font-semibold text-darkGray ">Elementos fundidos:</td>
                                        <td className="px-4 py-2 text-darkGray ">{project.elementos}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </section>

                {project.testimonio && (
                    <section className="flex flex-col border-t-4 border-darkGray mt-4 animate-slide-in-right">
                        <div className="flex flex-col sm:flex-row sm:justify-center gap-2 sm:gap-0 text-center sm:text-left lg:p-4 sm:p-1">
                            <h2 className="text-lg sm:text-xl lg:text-3xl font-boldCondensed text-bright-red">
                                Más información sobre el

                                <span className="text-lg sm:text-xl lg:text-3xl font-boldCondensed text-darkGray px-2">
                                    proyecto</span>
                            </h2>
                        </div>

                        <video
                            ref={secondaryVideoRef}
                            controls
                            className="w-full aspect-[16/9] mt-4 rounded-lg"
                            onPlay={handleSecondaryVideoPlay}
                            onPause={handleSecondaryVideoPause}
                        >
                            <source
                                src={`/images/proyectos/${project.description}/${project.testimonio}`}
                                type="video/mp4"
                            />
                            Tu navegador no soporta el video.
                        </video>
                    </section>)}

            </div>
        </div>

    );
}

export default ProyectoDetalle;
