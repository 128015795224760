import React from 'react';

const Footer = () => {


  return (
    <footer className="bg-darkGray text-white">
      <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
          <div className="sm:col-span-2">
            <a href="/" aria-label="Go home" title="Hormipen" className="inline-flex items-center">
              <img
                src="/images/logos/Logo.webp"
                width="auto"
                height="48"
                class="w-8 h-8 mr-2"
                alt="Hormipen Logo"
              />
              <img
                src="/images/logos/Logo-White.webp"
                width="auto"
                height="48"
                class="h-8 lg:h-9"
                alt="Hormipen Logo Blanco"
              />
            </a>
            <div className="mt-6 lg:max-w-sm">
              <p className="text-sm">
                Planta de hormigón premezclado para diferentes estructuras.
              </p>

            </div>
          </div>
          <div className="space-y-2 text-sm">
            <p className="text-lg font-boldCondensed tracking-wide ">Contacto</p>
            {/* Teléfono */}
            <div className="flex items-center">
              <svg
                className="w-5 h-5 mr-2 text-deep-purple-accent-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M6.62 10.79a15.053 15.053 0 006.59 6.59l2.2-2.2a1 1 0 011.11-.27 11.72 11.72 0 003.68.59 1 1 0 011 1v3.66a1 1 0 01-1 1A18.06 18.06 0 013 5a1 1 0 011-1h3.68a1 1 0 011 1 11.72 11.72 0 00.59 3.68 1 1 0 01-.26 1.11z" />
              </svg>
              <a
                href="tel:09849771071"
                aria-label="Our phone"
                title="Our phone"
                className="text-sm transition-colors duration-300 text-deep-purple-accent-400"
              >
                098 497 7107
              </a>
            </div>

            {/* Email */}
            <div className="flex items-center">
              <svg
                className="w-5 h-5 mr-2 text-deep-purple-accent-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M20.54 5H3.46A1.46 1.46 0 002 6.46v11.08A1.46 1.46 0 003.46 19h17.08A1.46 1.46 0 0022 17.54V6.46A1.46 1.46 0 0020.54 5zM20 7.62l-7.53 4.65a1 1 0 01-1 0L4 7.62V6l8 4.95L20 6z" />
              </svg>
              <a
                href="mailto:ventas@hormipen.com"
                aria-label="Our email"
                title="Our email"
                className="text-sm transition-colors duration-300 text-deep-purple-accent-400"
              >
                ventas@hormipen.com
              </a>
            </div>

            {/* Dirección */}
            <div className="flex items-center">
              <svg
                className="w-5 h-5 mr-2 text-deep-purple-accent-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M12 2a7 7 0 00-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 00-7-7zm0 9.5a2.5 2.5 0 110-5 2.5 2.5 0 010 5z" />
              </svg>
              <a
                href="https://www.google.com/maps/place/HORMIPEN/@-2.2307243,-80.8867854,17z/data=!4m6!3m5!1s0x902e09f13aafa55d:0xcb284de0772c52de!8m2!3d-2.2307779!4d-80.8848971!16s%2Fg%2F11h3_sg1j9?entry=ttu&g_ep=EgoyMDI0MTEyNC4xIKXMDSoJLDEwMjExMjM0SAFQAw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Our address"
                title="Our address"
                className="text-sm transition-colors duration-300 text-deep-purple-accent-400"
              >
                Av. Eleodoro Solorzano, La Libertad
              </a>
            </div>
          </div>
          <div>
            <span className="text-lg font-boldCondensed tracking-wide">Redes Sociales</span>
            <div className="flex items-center mt-1 space-x-3">
              <a
                href="https://www.instagram.com/hormipen/"
                className="transition-colors duration-300 hover:text-deep-purple-accent-400"
                aria-label="Visitar nuestra cuenta de Instagram"
              >
                <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                  <circle cx="15" cy="15" r="4"></circle>
                  <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z"></path>
                </svg>
              </a>
              <a
                href="https://www.facebook.com/hormipen"
                className="transition-colors duration-300 hover:text-deep-purple-accent-400"
                aria-label="Visitar nuestra cuenta de Facebook"
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                  <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
          <p className="text-sm">
            © Copyright 2025 Hormipen. Todos los derechos reservados.
          </p>
          <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
            <li>
              <a href="/" className="text-sm  transition-colors duration-300 hover:text-deep-purple-accent-400">Preguntas Frecuentes</a>
            </li>
            <li>
              <a href="/" className="text-sm transition-colors duration-300 hover:text-deep-purple-accent-400">Políticas de privacidad</a>
            </li>
            <li>
              <a href="/" className="text-sm  transition-colors duration-300 hover:text-deep-purple-accent-400">Términos &amp; Condiciones</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
