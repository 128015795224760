import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';


const features = [
    {
        id: 1,
        title: "01- Innovación Materiales",
        description: "Integramos avanzados materiales y tecnologías en nuestras mezclas. El resultado es un hormigón más fuerte, resistente y eficiente.",
        icon: <FontAwesomeIcon icon={faHeadset} className="text-bright-red-500 text-2xl" />

    },
    {
        id: 2,
        title: "02- Soporte Técnico",
        description: "Ofrecemos soporte técnico. Priorizamos la comunicación constante, demostrando compromiso con cada uno de nuestros clientes.",
        icon: <FontAwesomeIcon icon={faHeadset} className="text-bright-red-500 text-2xl" />

    },
    {
        id: 3,
        title: "03- Confianza Entregas",
        description: "Uno de nuestros pilares más importantes es crear confianza a través del cumplimiento de de entregas en las fechas acordadas.",
        icon: <FontAwesomeIcon icon={faHeadset} className="text-bright-red-500 text-2xl" />

    },
];

const FeatureGrid = () => {
    return (
        <section className="bg-gray-100">
            <div className="max-w-7xl w-full mx-auto text-center pt-12">
                <h1 className="px-4 text-lg sm:text-xl lg:text-3xl font-boldCondensed overflow-hidden text-darkGray">
                    En Hormipen, nos destacamos por:
                </h1>
               
            </div>
            <div className="max-w-screen-xl 2xl:max-w-screen-2xl px-8 md:px-12 mx-auto pb-8 space-y-24 flex flex-col justify-center ">
                {/* Grid de características */}

                <div
                    className="grid grid-cols-1 gap-8 my-12 list-none md:grid-cols-3 lg:my-20 max-w-5xl mx-auto"
                    role="list"
                >
                    {features.map((feature) => (
                        <article
                            key={feature.id}
                            className="mx-auto shadow-xl bg-cover bg-center min-h-150 relative border shadow "
                            role="listitem"
                        >
                            <div className="bg-white relative h-full min-h-150 flex flex-wrap flex-col ">
                                <div className="bg-white p-8 h-full  flex flex-col">
                                    <h2
                                        className={`text-lg sm:text-xl lg:text-3xl font-boldCondensed text-bright-red mt-2 mb-5`}
                                    >
                                        {feature.title}
                                    </h2>

                                    <p className="text-darkGray text-sm sm:text-base lg:text-lg">
                                        {feature.description}
                                    </p>
                                </div>
                            </div>
                        </article>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FeatureGrid;
