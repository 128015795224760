import React, { useState } from 'react';

const elementos = [
  {
    id: 1,
    titulo: 'Bordillo',
    descripcion: 'Producción y colocación de bordillos de alta calidad, ideales para proyectos urbanos y residenciales.',
    descripcionDetallada: 'Nuestros bordillos están diseñados para soportar las condiciones más exigentes, garantizando durabilidad y calidad. Utilizamos tecnología avanzada en la producción y aseguramos una instalación precisa para todo tipo de proyectos.',
    imagen: 'images/services/bordillo.webp',
  },
  {
    id: 2,
    titulo: 'Losa',
    descripcion: 'Construcción de losas de concreto resistentes y duraderas.',
    descripcionDetallada: 'Las losas que construimos son ideales para proyectos residenciales e industriales, con acabados precisos y resistencia comprobada en distintas condiciones climáticas.',
    imagen: 'images/services/losa.webp',
  },
  {
    id: 3,
    titulo: 'Piso',
    descripcion: 'Pisos industriales y decorativos con acabados excepcionales.',
    descripcionDetallada: 'Nuestros pisos están diseñados para combinar funcionalidad y estética. Son ideales para aplicaciones en hogares, industrias y oficinas, ofreciendo una solución resistente y visualmente atractiva.',
    imagen: 'images/services/piso.webp',
  },
  {
    id: 4,
    titulo: 'Muros',
    descripcion: 'Construcción de muros de contención y divisorios con técnicas innovadoras.',
    descripcionDetallada: 'Nuestros muros de contención están diseñados para brindar estabilidad y soporte en proyectos exigentes. Con el uso de técnicas innovadoras, garantizamos durabilidad y resistencia.',
    imagen: 'images/services/muros.webp',
  },
  {
    id: 5,
    titulo: 'Zapata',
    descripcion: 'Bases de zapatas diseñadas para brindar estabilidad y soporte a estructuras pesadas.',
    descripcionDetallada: 'Construimos zapatas sólidas y resistentes, perfectas para soportar estructuras pesadas en todo tipo de terrenos. Nuestra experiencia asegura precisión y eficiencia en cada proyecto.',
    imagen: 'images/services/zapata.webp',
  },
  {
    id: 6,
    titulo: 'Plinton',
    descripcion: 'Colocación de plintos robustos para proyectos de alta envergadura.',
    descripcionDetallada: 'Ofrecemos plintos diseñados para soportar grandes cargas y proporcionar una base sólida para estructuras de alto impacto. Garantizamos calidad en materiales y precisión en la instalación.',
    imagen: 'images/services/plinton.webp',
  },
  {
    id: 7,
    titulo: 'Columna',
    descripcion: 'Construcción de columnas de concreto reforzado para soportar estructuras complejas.',
    descripcionDetallada: 'Nuestras columnas están diseñadas para soportar las estructuras más complejas, con refuerzos de alta calidad y un enfoque en la durabilidad a largo plazo. Ideales para proyectos arquitectónicos e industriales.',
    imagen: 'images/services/columna.webp',
  },
];


const Elementos = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const openModal = (service) => {
    setSelectedService(service);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedService(null);
  };

  return (
    <div className=''>

      {/* LISTA DE elementos */}
      <div className="py-12 text-darkGray lg:py-16 duration-4000">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-xl mx-auto text-center xl:max-w-2xl animate-slide-in-left">
            <h2 className="text-lg sm:text-xl lg:text-3xl font-boldCondensed leading-tight mb-6 overflow-hidden">Nuestros elementos</h2>
            <p className="text-sm sm:text-base lg:text-lg mb-6">Descubre los elementos y productos que ofrecemos</p>
          </div>
          {/* Layout para pantallas grandes */}
          <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 mt-8 hidden lg:grid animate-slide-in-bottom">
            {elementos.map((service) => (
              <div
                key={service.id}
                className="relative cursor-pointer group bg-gray-100 rounded-lg overflow-hidden shadow-lg"
                onClick={() => openModal(service)}
              >
                <img
                  src={service.imagen}
                  alt={service.titulo}
                  className="w-full h-auto object-cover group-hover:scale-105 transition-transform duration-700"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <h2 className="text-white text-3xl font-boldCondensed">{service.titulo}</h2>
                </div>
              </div>
            ))}
          </div>

          {/* Layout para pantallas pequeñas */}
          <div className="grid gap-8 sm:grid-cols-2 mt-8 lg:hidden">
            {elementos.map(({ id, titulo, descripcionDetallada, imagen }) => (
              <div
                key={id}
                className="bg-gray-100 rounded-lg shadow-lg overflow-hidden"
              >
                <img
                  src={imagen}
                  alt={titulo}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h2 className="text-lg sm:text-xl text-center font-boldCondensed text-darkGray mb-4">{titulo}</h2>
                  <p className="text-sm sm:text-base text-darkGray text-justify" style={{ hyphens: "auto" }}>{descripcionDetallada}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      

      {/* MODAL */}
      {modalOpen && selectedService && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 lg:flex">
          <div className="bg-white rounded-lg max-w-xl w-full p-10 m-10 relative animate-slide-down-slow">
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-darkGray hover:text-darkGray"
            >
              ✕
            </button>

            <img
              src={selectedService.imagen}
              alt={selectedService.titulo}
              className="w-full h-auto object-cover rounded-lg mb-4"
            />
            <h2 className="text-lg sm:text-xl lg:text-3xl font-boldCondensed text-darkGray mb-4">{selectedService.titulo}</h2>
            <p className="text-sm sm:text-base lg:text-lg text-darkGray text-justify" style={{ hyphens: "auto" }}>{selectedService.descripcionDetallada}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Elementos;
