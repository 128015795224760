import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faMobileAlt, faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import emailjs from 'emailjs-com';

function Contacto() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                'service_jkxredo', // Reemplaza con tu Service ID de EmailJS
                'template_szx9qo8', // Reemplaza con tu Template ID de EmailJS
                form.current,
                '-wvXowk6M7B7Mq_Ue' // Reemplaza con tu Public Key de EmailJS
            )
            .then(
                (result) => {
                    alert('Mensaje enviado exitosamente.');
                    form.current.reset();
                },
                (error) => {
                    alert('Error al enviar el mensaje: ', error.text);
                }
            );
    };

    const contactInfo = [
        {
            title: "Dirección",
            content: "Av. Eleodoro Solorzano, La Libertad",
            icon: <FontAwesomeIcon icon={faMapMarkerAlt} className="text-bright-red text-2xl" />
        },
        {
            title: "Celular",
            content: "+593 984977107",
            icon: <FontAwesomeIcon icon={faMobileAlt} className="text-bright-red text-2xl" />
        },
        {
            title: "Email",
            content: "ventas@hormipen.com",
            icon: <FontAwesomeIcon icon={faEnvelope} className="text-bright-red text-2xl" />
        },
        {
            title: "Redes Sociales",
            content: (
                <div className="flex gap-4">
                    <a href="https://www.facebook.com/hormipen/" className="text-darkGray hover:text-bright-red">
                        <i className="fab fa-facebook"></i>
                    </a>
                    <a href="https://www.instagram.com/hormipen/" className="text-darkGray hover:text-bright-red">
                        <i className="fab fa-instagram"></i>
                    </a>
                    {/* <a href="#" className="text-darkGray hover:text-bright-red">
                        <i className="fab fa-linkedin"></i>
                    </a>
                    <a href="#" className="text-darkGray hover:text-bright-red">
                        <i className="fab fa-twitter"></i>
                    </a> */}
                </div>
            ),
            icon: <FontAwesomeIcon icon={faUser} className="text-bright-red" />
        },
    ];

    return (
        <div className="w-full p-6 lg:p-10 xl:p-15">
            <div className="flex py-4 sm:p-4 md:gap-10 justify-center">
                {/* Contenedor de texto
                <div className="lg:basis-1/3 text-left p-2 animate-slide-in-left">
                </div> */}

                {/* Formulario */}
                <form ref={form} onSubmit={sendEmail} className="w-[70vw] justify-center bg-gray-100 border border-gray-150 rounded-lg p-6 animate-slide-in-right">
                    <div className="flex flex-col gap-4">
                        {/* Inputs en columna en pantallas pequeñas y fila en pantallas grandes */}
                        <div className="flex flex-col lg:flex-row gap-4">
                            <input
                                type="text"
                                name="nombre"
                                placeholder="Nombre"
                                className="w-full p-3 bg-gray-50 text-darkGray rounded-lg border border-gray-150 placeholder-darkGray focus:ring-2 focus:ring-bright-red-500"
                                required
                            />
                            <input
                                type="text"
                                name="apellido"
                                placeholder="Apellido"
                                className="w-full p-3 bg-gray-50 text-darkGray rounded-lg border border-gray-150 placeholder-darkGray focus:ring-2 focus:ring-bright-red-500"
                                required
                            />
                        </div>

                        {/* Campo de correo */}
                        <input
                            type="email"
                            name="email"
                            placeholder="ejemplo@gmail.com"
                            className="w-full p-3 bg-gray-50 text-darkGray rounded-lg border border-gray-150 placeholder-darkGray focus:ring-2 focus:ring-bright-red-500"
                            required
                        />

                        {/* Campo de mensaje */}
                        <textarea
                            rows="4"
                            name="mensaje"
                            placeholder="Escribe tu mensaje aquí"
                            className="w-full p-3 bg-gray-50 text-darkGray rounded-lg border border-gray-150 placeholder-darkGray focus:ring-2 focus:ring-bright-red-500"
                            required
                        ></textarea>

                        {/* Botón de envío */}
                        <button
                            type="submit"
                            className="bg-bright-red text-lg text-white py-3 rounded-lg font-medium hover:opacity-70 transition-opacity duration-300"
                        >
                            Enviar Mensaje
                        </button>
                    </div>
                </form>
            </div>

            {/* Información de contacto en forma de grid responsive */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-14 py-4 sm:p-4 animate-slide-in-left">
                {contactInfo.map((info, index) => (
                    <div
                        key={index}
                        className="space-y-2 border-2 rounded-xl p-4 flex flex-col items-center text-center"
                    >
                        <div className="w-12 h-12 flex items-center justify-center">
                            {info.icon}
                        </div>
                        <h2 className="text-lg sm:text-xl lg:text-3x font-boldCondensed text-darkGray">{info.title}</h2>
                        <p className="text-sm sm:text-base lg:text-lg text-darkGray">{info.content}</p>
                    </div>
                ))}
            </div>

            {/* Mapa de ubicación */}
            <div className="mt-4 animate-slide-in-right">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2966.140579231907!2d-80.8847923004601!3d-2.231406220560171!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902e09f13aafa55d%3A0xcb284de0772c52de!2sHORMIPEN!5e0!3m2!1ses-419!2sec!4v1732641082965!5m2!1ses-419!2sec"
                    width="100%"
                    height="300"
                    allowFullScreen=""
                    loading="lazy"
                    className="rounded-lg border border-darkGray"
                    title="Mapa de ubicación de Hormipen"
                ></iframe>
            </div>
        </div>


    );
};

export default Contacto;