import React, { createContext, useContext } from "react";

// Datos de los proyectos
const projects = [
  {
    id: "pesqueraexu", title: "2021 · Chanduy", description: "PESQUERA EXU",
    image: "4.webp", video: "exu.mp4", 
    detalle: "Este proyecto se llevó a cabo en la ciudad de Chanduy con el propósito de construir una planta pesquera moderna y eficiente que optimizara los procesos de producción y almacenamiento. El principal objetivo era garantizar una infraestructura de calidad que cumpla con estándares industriales y ambientales, permitiendo operaciones sostenibles y productivas.",
    cliente: "PESQUERA EXU - EXU S.A",
    lugar: "Chanduy, Ecuador",
    fechaInicio: "2021-01-18", // Nuevo dato
    caracteristicas: "Se utilizaron diferentes tipos de concreto: 280 D. Normal para los cimientos, 350 D. Normal para áreas de alta resistencia, 350 D. Chispa para fraguado rápido y 4.5 MPA D. Normal para acabados precisos.",
    volumenTotal: "756 m³",
    elementos: "Piso industrial y plintos",
    isCritical: true, // No crítico
  },
  {
    id: "countryclub", title: "2022 · Salinas", description: "Country Club",
    image: "countryClub.webp", video: "countryClub.mp4", testimonio:"cliente.mp4",
    detalle: "Este proyecto se desarrolló en la ciudad de Salinas con el objetivo de construir una infraestructura habitacional moderna y funcional, enfocada en cumplir con los estándares de calidad requeridos por el cliente. Se priorizó la creación de una cisterna eficiente, una cimentación robusta y pisos duraderos que garantizaran seguridad y resistencia. ",
    cliente: "Urbanización Country Club - Constructora Toral",
    lugar: "Salinas, Ecuador",
    fechaInicio: "2022-11-28", // Nuevo dato
    caracteristicas: "Se utilizaron concretos de 210 D. Normal para los elementos estructurales básicos y 240 D. Normal para áreas que requerían mayor resistencia.",
    volumenTotal: "631 m³",
    elementos: "Cisterna, cimentación y pisos"
  },
  {
    id: "santacecilia", title: "2023 · Via Ancon", description: "Santa Cecilia",
    image: "santaCecilia.webp", video: "santaCecilia.mp4",
    detalle: "Este proyecto se realizó en la Vía Ancón y consistió en la construcción de diversas estructuras esenciales para el desarrollo de la urbanización Santa Cecilia II. El objetivo principal fue proporcionar elementos funcionales y de alta durabilidad, cumpliendo con las especificaciones técnicas del cliente y garantizando un resultado de calidad. Entre los elementos destacados se encuentran plintos, riostras, cisternas y columnas, diseñados para satisfacer las necesidades de infraestructura habitacional moderna.",
    cliente: "Urbanización Santa Cecilia II - Espacios y Diseños Berriot",
    lugar: "Vía Ancón, Ecuador",
    fechaInicio: "2023-03-21",
    caracteristicas: "Se utilizaron concretos de distintas resistencias para atender las necesidades específicas de cada área del proyecto. El concreto 210 D. Normal fue empleado para estructuras generales, mientras que el 240 y 280 D. Normal brindaron mayor resistencia en áreas clave. Además, el concreto 280 y 210 D. Chispa se utilizó en zonas que requerían un fraguado rápido, asegurando la continuidad de la obra.",
    volumenTotal: "686.5 m³",
    elementos: "Plintos, riostras, muro, cisterna, losa, columnas, contrapiso, piscina y cámara"
  },
  {
    id: "salerno", title: "2024 · Salinas", description: "Salerno",
    image: "salerno.webp", video: "salerno.mp4",
    detalle: "Este proyecto, ubicado en la ciudad de Salinas, se enfocó en la construcción del Edificio Salerno, una estructura diseñada para cumplir con altos estándares de calidad y funcionalidad. El objetivo principal fue garantizar una cimentación sólida, losas resistentes y columnas duraderas que sostuvieran el diseño arquitectónico del edificio.",
    cliente: "Edificio Salerno - Cornisacorp",
    lugar: "Salinas, Ecuador",
    fechaInicio: "2024-02-21",
    caracteristicas: "Se utilizó concreto 350 D. Normal, seleccionado por su capacidad de garantizar el desempeño estructural necesario para el proyecto.",
    volumenTotal: "1,163.5 m³",
    elementos: "Cimentación, losas y columnas"
  },
  {
    id: "cerezal", title: "2023 · Cerezal", description: "Puente Cerezal",
    image: "cerezal.webp", video: "cerezal.mp4",
    detalle: "Ubicado en Cerezal, este proyecto consistió en la construcción del Puente Cerezal, diseñado para garantizar conectividad y durabilidad en una de las infraestructuras clave de la región. Con un enfoque en la estabilidad estructural y la seguridad, se utilizó concreto de alta resistencia para satisfacer las exigencias del diseño y las condiciones ambientales del área. El resultado fue una obra robusta y funcional que contribuye al desarrollo vial de la comunidad.",
    cliente: "Puente Cerezal - CR Construcción",
    lugar: "Cerezal, Ecuador",
    fechaInicio: "2023-01-13",
    caracteristicas: "Se empleó concreto 280 D. Normal, ideal para garantizar la resistencia y estabilidad necesarias para los elementos del puente.",
    volumenTotal: "583 m³",
    elementos: "Cimentación, pantalla y losa de rodadura"
  },
  {
    id: "puntasurf", title: "2022 · Ballenita", description: "Punta Surf",
    image: "puntaSurf.webp", video: "puntaSurf.mp4",
    detalle: "En Ballenita, este proyecto se enfocó en la construcción del Edificio Punta Sur, una estructura moderna diseñada para satisfacer altos estándares de calidad y funcionalidad. Utilizando diversos tipos de concreto, se garantizaron la resistencia y durabilidad necesarias para cada etapa del proceso constructivo. Este proyecto representa un avance significativo en infraestructura, con un enfoque en estabilidad y eficiencia.",
    cliente: "Edificio Punta Sur",
    lugar: "Ballenita, Ecuador",
    fechaInicio: "2022-09-02",
    caracteristicas: "Se emplearon concretos de distintas resistencias, como 240 D. Normal y 280 D. Normal, así como concreto de rápido fraguado (210 D. Chispa, 240 D. Chispa y 280 D. Chispa), adaptándose a los requerimientos estructurales del proyecto.",
    volumenTotal: "4.739 m³",
    elementos: "Cimentación, muro de contención, columnas y losa"
  },
  {
    id: "skatepark", title: "2023 · Las Tunas", description: "Skate Park Las Tunas",
    image: "skatePark.webp", video: "skatePark.mp4",
    detalle: "Ubicado en Las Tunas, este proyecto se centró en la construcción de un Skate Park moderno para la Fundación del Mar. Con un diseño pensado para promover actividades recreativas, se empleó concreto de rápido fraguado para asegurar un acabado duradero y de alta calidad, ideal para el uso intensivo y las necesidades específicas de este espacio.",
    cliente: "Skate Park - Fundación del Mar",
    lugar: "Las Tunas, Ecuador",
    fechaInicio: "2023-06-14",
    caracteristicas: "Concreto de rápido fraguado (240 D. Chispa) utilizado para garantizar resistencia y precisión en el piso del Skate Park.",
    volumenTotal: "35 m³",
    elementos: "Piso"
  },
  {
    id: "casadelsol", title: "2024 · Ayangue", description: "Casas del Sol",
    image: "casadelsol.webp", video: "casadelsol.mp4",
    detalle: "Este proyecto en Ayangue se enfocó en el desarrollo de la Urbanización Casas del Sol, integrando elementos estructurales de alta calidad para garantizar un diseño robusto y funcional. Se empleó concreto con diferentes resistencias para cubrir los requerimientos de cimentación, muros y áreas recreativas como piscinas, asegurando precisión y durabilidad en todos los acabados.",
    cliente: "Urbanización Casas del Sol - Arkitrust",
    lugar: "Ayangue, Ecuador",
    fechaInicio: "2024-08-07",
    caracteristicas: "Se utilizó concreto 280 D. Chispa y 280 D. Normal para asegurar resistencia en cimentación y elementos recreativos.",
    volumenTotal: "225 m³",
    elementos: "Cimentación, muro, plintos, columnas, losa y piscina"
  },
  {
    id: "sixtoduran", title: "2022 · Santa Elena", description: "Sixto Duran Ballen",
    image: "sixtoDuran.webp", video: "sixtoDuran.mp4",
    detalle: "En Santa Elena, este proyecto se dedicó a la construcción del Parque Ecológico Sixto Durán Ballén, que incluyó la creación de piscinas, aceras y bordillos para mejorar la infraestructura urbana y proporcionar espacios recreativos de calidad. El concreto utilizado cumplió con las especificaciones técnicas necesarias para garantizar durabilidad y resistencia.",
    cliente: "Parque Ecológico Sixto Durán Ballén",
    lugar: "Santa Elena, Ecuador",
    fechaInicio: "2022-01-11",
    caracteristicas: "Se emplearon concretos 240 D. Chispa, 280 D. Chispa y 210 D. Normal, adaptados a las distintas necesidades de resistencia estructural del proyecto.",
    volumenTotal: "791 m³",
    elementos: "Piscinas, aceras y bordillos"
  },
];

// Crear el contexto
const ProjectContext = createContext();

// Crear el proveedor del contexto
export const ProjectProvider = ({ children }) => {
  return (
    <ProjectContext.Provider value={projects}>
      {children}
    </ProjectContext.Provider>
  );
};

// Crear un hook para usar el contexto fácilmente
export const useProjects = () => useContext(ProjectContext);

export default ProjectContext;
