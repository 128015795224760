import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

const CenteredCarousel = () => {
  const slides = [
    { content: "Proyecto 1", image: "images/proyectos/PESQUERA EXU/projectPesquera.png" },
    { content: "Proyecto 2", image: "images/proyectos/Casas del Sol/proyectoCasaSol.png" },
    { content: "Proyecto 3", image: "images/proyectos/Country Club/proyectoCountry.png" },
    { content: "Proyecto 4", image: "images/proyectos/Puente Cerezal/projectCerezal.png" },
    { content: "Proyecto 4", image: "images/proyectos/Punta Surf/projectSurf.png" },
    { content: "Proyecto 5", image: "images/proyectos/Salerno/projectSalerno.jpg" },
    { content: "Proyecto 6", image: "images/proyectos/Santa Cecilia/projectSantaCe.png" },
    { content: "Proyecto 7", image: "images/proyectos/Sixto Duran Ballen/projectDuranBallen.png" },

  ];

  return (
    <div className="w-full relative">
      <Swiper
        modules={[Pagination, Autoplay]} // Agregar Autoplay
        autoplay={{
          delay: 5000, // Avanza cada 5 segundos
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        centeredSlides={true}
        loop={true}
        spaceBetween={30}
        breakpoints={{
          1920: {
            slidesPerView: Math.min(4, slides.length), // Adapta según la cantidad de slides
            spaceBetween: 60,
          },
          1028: {
            slidesPerView: Math.min(2, slides.length),
            spaceBetween: 10,
          },
          990: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
        className="centered-slide-carousel"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="relative bg-indigo-50 rounded-2xl h-96 w-full flex justify-center items-center">
              <img
                src={slide.image}
                alt={slide.content}
                className="absolute inset-0 h-full w-full object-cover object-left rounded-2xl mx-auto"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CenteredCarousel;
