import React from "react";
import { Link } from "react-router-dom";
import { useProjects } from "./ProjectContext";

const imageFolder = "/images/proyectos/";

function Proyectos() {
  const projects = useProjects();

  React.useEffect(() => {
    // Pre-cargar imágenes críticas
    const preloadCriticalImages = () => {
      projects
        .filter((project) => project.isCritical) // Filtrar solo imágenes críticas
        .forEach((project) => {
          const link = document.createElement("link");
          link.rel = "preload";
          link.as = "image";
          link.href = `${imageFolder}${project.description}/${project.image}`;
          document.head.appendChild(link); // Añadir al <head>
        });
    };

    preloadCriticalImages();
  }, [projects]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 animate-slide-in-right">
      {projects.map((project) => (
        <Link
          key={project.id}
          to={`/proyectos/${project.id}`}
          className="relative overflow-hidden group block"
        >
          <img
            src={`${imageFolder}${project.description}/${project.image}`}
            alt={project.description}
            className={`w-full object-cover transform transition-transform duration-300 group-hover:scale-110 ${
              project.isCritical ? "" : "lazy"
            }`}
            loading={project.isCritical ? "eager" : "lazy"}
            width="1200"
            height="800"
          />
          <div className="w-full absolute inset-0 bg-black bg-opacity-30 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
            <div className="text-white">
              <h3 className="text-xl font-boldCondensed">{project.title}</h3>
              <p className="text-lg">{project.description}</p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default Proyectos;
