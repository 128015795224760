import React, { useState } from 'react';

const Nosotros = () => {
  const [activeVideo, setActiveVideo] = useState(null);

  const videos = [
    { id: 1, portada: 'images/about_us/portadaJairoCatuto.webp', video: 'videos/about_us/videoJairoCatuto.mp4', nombre: 'Jairo Catuto', cargo: 'Maestro de obra' },
    { id: 2, portada: 'images/about_us/portadaFranciscoBayancela.webp', video: 'videos/about_us/videoFranciscoBayancela.mp4', nombre: 'Francisco Bayancela', cargo: 'Arquitecto' },
    { id: 3, portada: 'images/about_us/portadaNestorCulcay.webp', video: 'videos/about_us/videoNestorCulcay.mp4', nombre: 'Nestor Culcay', cargo: 'Maestro de obra' },
  ];

  return (
    <div className='px-4 py-12 lg:py-16 duration-4000 text-darkGray'>

      <div className="sm:flex items-center max-w-screen-2xl">
        <div className="sm:w-1/2 p-5 lg:p-10 animate-slide-in-left">
          <div className="image object-center text-center">
            <img
              src="images/nosotros1.webp"
              alt="Imagen del equipo Hormipen"
            />
          </div>
        </div>
        <div className="sm:w-1/2 p-5 animate-slide-in-right">
          <div className="text">
            <span className="text-lg sm:text-xl lg:text-3xl border-b-2 border-bright-red font-boldCondensed">Quiénes Somos</span>
            <p className="text-sm sm:text-base lg:text-lg text-justify font-sans mt-4" style={{ hyphens: "auto" }}>
              Hormipen es una empresa orgullosamente establecida en la provincia de Santa Elena, Ecuador. Desde sus inicios como
              un negocio familiar dedicado a la venta de hormigón de alta calidad, ha crecido y evolucionado con el tiempo,
              destacándose por su eficiencia y compromiso.
            </p>
            <p className="text-sm sm:text-base lg:text-lg text-justify font-sans" style={{ hyphens: "auto" }}>
              Impulsada por un equipo humano altamente capacitado, Hormipen se ha convertido en un referente en la región,
              desempeñando un papel crucial en la transformación de la provincia. La empresa combina productos innovadores y
              tecnologías de vanguardia para mejorar la infraestructura local, asegurando que cada proyecto cumpla con los más
              altos estándares de calidad.

            </p>
          </div>
        </div>
      </div>

      {/* Misión, Visión y Valores */}
      {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-4 py-8 max-w-7xl mx-auto mb-8"> */}
      <div className='flex px-4 py-8 '>
        <div className="flex flex-col items-center text-darkGray justify-center md:w-1/2 gap-6 px-4 py-8 max-w-7xl mx-auto animate-slide-in-left ">
          <div className="bg-gray-100 w-full md:w-5/6 p-12 rounded-lg shadow-lg ">
            <h2 className="text-lg sm:text-xl lg:text-3xl text-center font-boldCondensed mb-6">Nuestra Misión</h2>
            <p className="text-sm sm:text-base lg:text-lg md:text-justify" style={{ hyphens: "auto" }}>
              Proveer hormigón de alta calidad y elementos innovadores que respalden el desarrollo sostenible, garantizando excelencia en cada proyecto y contribuyendo al progreso de la comunidad.
            </p>
          </div>

          {/* Visión */}
          <div className="bg-gray-100 w-full md:w-5/6 p-12 rounded-lg shadow-lg">
            <h2 className="text-lg sm:text-xl lg:text-3xl text-center font-boldCondensed mb-6">Nuestra Visión</h2>
            <p className="text-sm sm:text-base lg:text-lg md:text-justify" style={{ hyphens: "auto" }}>
              Convertirnos en líderes de la industria, reconocidos por nuestro compromiso con la calidad, la innovación y la mejora continua para transformar y desarrollar nuestra comunidad.
            </p>
          </div>

          {/* Valores */}
          <div className="bg-gray-100 w-full md:w-5/6 p-12 rounded-lg shadow-lg">
            <h2 className="text-lg sm:text-xl lg:text-3xl text-center font-boldCondensed mb-6">Nuestros Valores</h2>
            <ul className="text-sm sm:text-base lg:text-lg md:text-justify list-disc list-inside" style={{ hyphens: "auto" }}>
              <li>Compromiso con la calidad y la innovación</li>
              <li>Responsabilidad social y ambiental</li>
              <li>Trabajo en equipo y colaboración</li>
              <li>Ética y transparencia en nuestras operaciones</li>
            </ul>
          </div>
        </div>
        <div className="hidden lg:flex items-center justify-center w-1/2 animate-slide-in-right">
          <video className="w-auto max-h-screen rounded-lg" autoPlay loop muted>
            <source src="/videos/Equipo trabajando.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>



      {/* Entrevistas a trabajadores */}
      <div className="flex flex-col animate-slide-in-left">
        <div className="flex flex-col mt-8">
          <div className="container max-w-7xl px-4">
            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-lg sm:text-xl lg:text-3xl font-boldCondensed mb-8">
                  Conoce a nuestro equipo
                </h2>
                <p className="text-sm sm:text-base lg:text-lg font-sans">
                  Te presentamos a los profesionales que han colaborado en distintos proyectos, aportando su experiencia y compromiso.                </p>
              </div>
            </div>

            <div className="flex flex-wrap">
              {videos.map(({ id, nombre, cargo, portada, video }) => (
                <div key={id} className="w-full md:w-6/12 lg:w-4/12 mb-6 px-6 sm:px-6 lg:px-4">
                  <div className="flex flex-col">
                    {activeVideo === id ? (
                      <video
                        src={video}
                        controls
                        autoPlay
                        className="w-auto h-full object-cover rounded-lg shadow-lg"
                        onEnded={() => setActiveVideo(null)} // Regresa a la portada al finalizar
                      />
                    ) : (
                      <div
                        className="mx-auto relative group cursor-pointer"
                        onClick={() => setActiveVideo(id)}
                      >
                        <img
                          className="rounded-2xl drop-shadow-md hover:drop-shadow-xl transition-all duration-200 delay-100"
                          src={portada}
                          alt={nombre}
                        />
                        <div className="absolute inset-0 rounded-2xl bg-gray-100 bg-opacity-0 group-hover:bg-opacity-50 transition duration-300 flex items-center justify-center">
                          <button className="bg-white p-3 rounded-full shadow-lg hover:scale-110 transition-transform">
                            ▶
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="text-center mt-6">
                      <h2 className="text-lg sm:text-xl lg:text-3xl font-boldCondensed mb-1">{nombre}</h2>
                      <div className="text-sm sm:text-base lg:text-lg font-sans mb-2">{cargo}</div>
                    </div>
                  </div>
                </div>
              ))}

            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Nosotros;
